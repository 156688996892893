.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .overlay-content h2 {
    color: orangered;
  }

  .overlay-content li {
    text-align: left;
  }

  .overlay-content {
    background: white;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
  }

  .overlay-content .h2 {
    color: orangered;
  }
  
  .close-button {
    margin-top: 20px;
    padding: 10px 20px;
    background: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .close-button:hover {
    background: #0056b3;
  }
  